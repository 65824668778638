const CLIENT_CONFIG = {
  name: "Yoube",
  numTutorialImages: 14,
  showAvatarCampaigns: true,
  apiEndpoint: "https://api-yoube.whappy.it/api/",
  primaryColor: "#882737",
  primaryColor_2: "#882737",
  secondaryColor: "#fbd9c9",
  secondaryColor_2: "#fbd9c9",
  thirdaryColor: "#5f422e",
  thirdaryColor_2: "#838788",
  graphLightColor_1: "#4089bf",
  graphLightColor_2: "#f5a502",
  graphDarkColor_1: "#4089bf",
  graphDarkColor_2: "#f5a502",
}
export default CLIENT_CONFIG
